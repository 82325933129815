import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import useMediaQuery from "../hooks/use-media-query";
import Seo from "../components/Seo";

const UpcomingCoursesPage = ({ data, location }) => {
  const [learningMethod, setLearningMethod] = useState("part-time-courses");
  const mediaQueryMatches = useMediaQuery("(max-width: 768px)");
  const courses = data.allSanityCourse.nodes;
  const learningMethods = data.allSanityLearningMethod.nodes;

  useEffect(() => {
    // chech url search params and set learning method
    const searchParams = new URLSearchParams(location.search);
    const learningMethodSearchParam = searchParams.get("learning-method");

    if (
      searchParams.has("learning-method") &&
      Boolean(
        learningMethods.find(
          (method) => method.slug.current === learningMethodSearchParam
        )
      )
    ) {
      setLearningMethod(learningMethodSearchParam);
    }
  }, [learningMethods, location.search]);

  // courses that match the selected learning method and that are upcoming
  const filteredCourses = courses.filter((course) => {
    const matchedLearningMethod = course.courseStartDates.find(
      (instance) => instance.learningMethod.slug.current === learningMethod
    );

    return (
      matchedLearningMethod && new Date(matchedLearningMethod.startDate) > new Date()
    );
  });

  // sort courses ascending by start date
  filteredCourses.sort((courseA, courseB) => {
    const courseAStartDate = courseA.courseStartDates.find(
      (instance) => instance.learningMethod.slug.current === learningMethod
    ).startDate;
    const courseBStartDate = courseB.courseStartDates.find(
      (instance) => instance.learningMethod.slug.current === learningMethod
    ).startDate;

    return new Date(courseAStartDate) - new Date(courseBStartDate);
  });

  const learningMethodChangeHandler = (event) => {
    const newLearningMethod = event.target.value;
    setLearningMethod(newLearningMethod);

    // below code causes redirect to 404 page
    // const learningMethodSearchParams = new URLSearchParams({
    //   "learning-method": newLearningMethod,
    // });
    // navigate(`${location.pathname}?${learningMethodSearchParams}`);
  };

  // renders different table datas (cells) in a row based on screen size
  const coursesTableRowElements = filteredCourses.map((course) => {
    const matchedCourseInstance = course.courseStartDates.find((instance) => {
      return instance.learningMethod.slug.current === learningMethod;
    });

    if (mediaQueryMatches) {
      return (
        <tr key={course.slug.current}>
          <td>
            <Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}/`}>
              {course.title}
            </Link>
          </td>
          <td>
            <Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}/`}>
              {new Date(matchedCourseInstance.startDate).toLocaleDateString("en-ZA", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </Link>
          </td>
          <td className="tt-lowercase">
            <Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}/`}>
              {matchedCourseInstance.duration}
            </Link>
          </td>
        </tr>
      );
    }

    return (
      <tr key={course.slug.current}>
        <td>
          <Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}/`}>
            {course.title}
          </Link>
        </td>
        <td className="tt-lowercase">
          <Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}/`}>
            {matchedCourseInstance.duration}
          </Link>
        </td>
        <td>
          <Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}/`}>
            {new Date(matchedCourseInstance.startDate).toLocaleDateString("en-ZA", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Link>
        </td>
        <td>
          <Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}/`}>
            {matchedCourseInstance.classTimes}
          </Link>
        </td>
        <td>
          <Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}/`}>
            {matchedCourseInstance.learningMethod.title.replace(" Courses", "")}
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <StyledUpcomingCoursesPage>
      <Seo title="Upcoming Courses" pathname="/upcoming-courses" />
      <h1>Upcoming Courses</h1>

      <p className="breadcrumbs">
        <Link to="/">Home</Link> {`>`}&nbsp; Upcoming Courses
      </p>

      <div className="input-container">
        <label htmlFor="learning-method">Choose a Learning Method</label>
        <select
          id="learning-method"
          name="learning-method"
          value={learningMethod}
          onChange={learningMethodChangeHandler}
        >
          {learningMethods.map(({ title, slug }) => (
            <option key={slug.current} value={slug.current}>
              {title}
            </option>
          ))}
        </select>
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              {mediaQueryMatches ? (
                <>
                  <th>Course</th>
                  <th>Start Date</th>
                  <th>Duration</th>
                </>
              ) : (
                <>
                  <th>Course</th>
                  <th>Duration</th>
                  <th>Start Date</th>
                  <th>Class Times</th>
                  <th>Learning Method</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>{coursesTableRowElements}</tbody>
        </table>
      </div>
    </StyledUpcomingCoursesPage>
  );
};

export const query = graphql`
  query UpcomingCoursesPageQuery {
    allSanityCourse(sort: { fields: [courseStartDates___startDate, title] }) {
      nodes {
        title
        slug {
          current
        }
        studyFields {
          slug {
            current
          }
        }
        courseStartDates {
          learningMethod {
            title
            slug {
              current
            }
          }
          classTimes
          duration
          startDate
        }
      }
    }
    allSanityLearningMethod(sort: { fields: rank }) {
      nodes {
        title
        slug {
          current
        }
      }
    }
  }
`;

const StyledUpcomingCoursesPage = styled.main`
  margin: auto;
  padding: 6rem 4rem 4rem 4rem;
  max-width: 1500px;

  .tt-lowercase {
    text-transform: lowercase;
  }

  .input-container {
    max-width: 300px;
    margin-bottom: 2rem;

    label {
      display: block;
      color: var(--primary-color);
      margin-bottom: 0.8rem;
      font-size: 1.2rem;
      font-weight: 600;
    }

    select {
      display: block;
      color: var(--primary-color); // #999
      width: 100%;
      padding: 0.8em;
      font-size: 1.1rem;
      font-weight: 400;
      font-family: "Montserrat";
      border: solid 1px var(--primary-color); // #ddd

      :focus,
      &:active {
        outline-color: var(--primary-color);
      }
    }
  }

  div.table-container {
    overflow: scroll;
  }

  table {
    --cell-padding: 0.6rem;
    width: 100%;
    min-width: 800px;
    border-spacing: 0;
    overflow: visible;

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: var(--cell-padding);
      text-decoration: none;
    }

    thead {
      color: var(--primary-color);
      text-align: left;
      text-transform: uppercase;

      th {
        padding: var(--cell-padding);
      }
    }

    tr {
      &:nth-child(even) {
        background-color: #f8f4f4;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 4rem 1rem;
  }

  @media (max-width: 768px) {
    padding: 2rem 0.5rem;

    div.table-container {
      overflow: initial;
      /* width: 100%; */
    }

    table {
      --cell-padding: 0.4rem 0rem 0.4rem 0.3rem;
      min-width: initial;
      font-size: 0.85rem;
    }
  }
`;

export default UpcomingCoursesPage;
